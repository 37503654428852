<template>
<div>
  <div class="container-fluid mx-auto" v-if="item">
    <div class="flex flex-wrap -mx-4">
      <sb-render v-for="child in item.content" :item="child" :key="child._uid" />
    </div>
  </div>
</div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'Column',
  extends: Blok
}
</script>

<style scoped lang="scss">

</style>
